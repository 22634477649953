import React, { useState } from "react"
import PropTypes from "prop-types"

import classNames from "classnames"
import { Text, Title } from "@nerdwallet/react-typography"
import Button from "@src/components/Button"
import Box from "@src/components/Box"
import LoanApplicationForm from "@src/components/LoanApplicationForm"
import { NumericFormat } from "react-number-format"
import { SIGN_UP_TEXT, FUNDERA_BY_NERDWALLET } from "@src/lib/constants"

import styles from "./ApplyCTA.module.less"

const ApplyCTA = props => {
  const [amount, setAmount] = useState(null)
  const responsive = !props.small

  return (
    <Box className={styles.box}>
      <div
        className={classNames(styles.ctaContainer, {
          [styles.responsiveCtaContainer]: responsive,
        })}
      >
        <div
          className={classNames(styles.howNeed, {
            [styles.responsiveHowNeed]: responsive,
          })}
        >
          <Title
            size="large"
            className={styles.title}
            style={{ fontWeight: 700 }}
          >
            How Much Do You Need?
          </Title>
        </div>
        <div
          className={classNames(styles.applyNow, {
            [styles.responsiveApplyNow]: responsive,
          })}
        >
          <LoanApplicationForm amount={amount} ctaType="Form CTA">
            {" "}
            <NumericFormat
              className={styles.input}
              decimalScale={2}
              thousandSeparator
              placeholder="$0"
              prefix="$"
              name="company_loan_amount"
              onValueChange={({ floatValue }) => setAmount(floatValue)}
              value={amount}
            />
            <div className={styles.applyNowButton}>
              <Button
                type="submit"
                primary
                loading={false}
                className={(styles["apply-button"], "clickable")}
                data-nw-mp
                data-cta-type="Form CTA"
                rel="sponsored"
                aria-label="Form CTA"
              >
                {SIGN_UP_TEXT}
              </Button>
              <Text small color="neutral-darker" className={styles.onWebsite}>
                {" " + FUNDERA_BY_NERDWALLET}
              </Text>
            </div>
          </LoanApplicationForm>
        </div>
      </div>
    </Box>
  )
}

ApplyCTA.propTypes = {
  small: PropTypes.bool,
}

export default ApplyCTA
